import React from "react";
import styled from "styled-components";

function ContentGrid({ title, totalPosts }) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Count>{totalPosts} posts</Count>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: var(--spacing-1);
`;

const Title = styled.h1`
  font-size: var(--font-size-h2);
`;

const Count = styled.span``;

export default ContentGrid;

import React from "react";
import { graphql } from "gatsby";
import qs from "qs";
import Layout from "../components/Layout";
import PostList from "../components/PostList";

export default function ({ data }) {
  const { nodes } = data.allMarkdownRemark;
  const search =
    typeof document === `undefined` ? "" : document.location.search;
  const { tag = "", page = "1" } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const posts = nodes.filter((node) =>
    tag ? node.fields.tags.includes(tag) : true
  );
  const totalPosts = posts.length;
  const postsPerPage = 20;
  const totalPages = Math.ceil(totalPosts / postsPerPage);
  const skip = (parseInt(page) - 1) * postsPerPage;

  return (
    <Layout>
      <PostList
        tag={tag}
        posts={posts}
        totalPages={totalPages}
        totalPosts={totalPosts}
        postsPerPage={postsPerPage}
        page={page}
        skip={skip}
      />
    </Layout>
  );
}

export const query = graphql`
  query listPosts {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(truncate: true, pruneLength: 300)
        timeToRead
        fields {
          slug
          tags
        }
        frontmatter {
          title
          date(formatString: "MMM D, YYYY")
        }
      }
    }
  }
`;

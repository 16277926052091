import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

function Pagination({ totalPages, tag, page }) {
  return (
    <Wrapper>
      {Array(totalPages)
        .fill()
        .map((_, i) => (
          <Item
            key={i}
            active={i + 1 === page}
            to={`?tag=${tag}&page=${i + 1}`}
          >
            {i + 1}
          </Item>
        ))}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  justify-content: center;
`;

const Item = styled(Link)`
  display: grid;
  place-items: center;
  color: var(--color-text);
  font-size: var(--font-size-4);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${(p) => p.active && "var(--color-primary)"};
  opacity: 0.7;

  &:hover {
    background: var(--color-primary);
    opacity: 0.4;
  }
`;

export default Pagination;

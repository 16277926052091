import React from "react";
import styled from "styled-components";
import ContentCard from "./ContentCard";

function ContentGrid({ posts }) {
  return (
    <Wrapper>
      {posts.map((post) => (
        <ContentCard key={post.fields.slug} item={post} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  @media (min-width: 34.375rem) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
`;

export default ContentGrid;

import React from "react";
import styled from "styled-components";
import Header from "./Header";
import ContentGrid from "./ContentGrid";
import Pagination from "./Pagination";

function Layout({
  tag,
  posts,
  totalPages,
  totalPosts,
  postsPerPage,
  page,
  skip,
}) {
  return (
    <Wrapper>
      <Header title={tag} totalPosts={totalPosts} />
      <ContentGrid posts={posts.slice(skip, skip + postsPerPage)} />
      <Pagination totalPages={totalPages} tag={tag} page={page} />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  margin: var(--spacing-8) auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
`;

export default Layout;

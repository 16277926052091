import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
// import Tags from "./Tags";

export default function ({ item }) {
  const {
    excerpt,
    timeToRead,
    fields: { slug, tags },
    frontmatter: { title },
  } = item;
  return (
    <Wrapper>
      <Title>
        <Link to={slug}>{title}</Link>
      </Title>
      <Excerpt>{excerpt}</Excerpt>
      {/* <Tags tags={tags} /> */}
    </Wrapper>
  );
}

const Wrapper = styled.article`
  background: var(--color-background-secondary);
  border-radius: var(--spacing-4);
  padding: var(--spacing-8);
  display: flex;
  gap: var(--spacing-4);
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.h3`
  font-size: var(--font-size-h4);
`;

const Excerpt = styled.p`
  font-size: var(--font-size-4);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
`;
